import React, { useEffect, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import "./styles.css";

export default function SelectDropdown({ list, value, setValue, label }) {
    const [search, setSearch] = useState("");

    useEffect(() => {
        setSearch("")
    }, [label])

    return (<Dropdown style={{ width: "100%" }} className="dropdown-inline" >
        <Dropdown.Toggle className='widget-dr-toggle' variant="transparent" >
            {value ? value : "Select " + label}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ minWidth: "100%" }} className="dropdown-menu widget-dr-menu dropdown-menu-xl">
            <div className="navi navi-hover">
                <li className="navi-item" style={{ padding: "0.75rem 1.5rem" }}>
                    <Form.Control className='' maxLength={10} type="text" placeholder={`Search ${label}`} value={search} onChange={e => setSearch(e.target.value)} />
                </li>
                <Dropdown.Divider className="m-0" />
                <div className="scroll" style={{ maxHeight: "25vh", position: "relative", overflowY: "auto" }}  >
                    {list.map((option, index) => option.label && option.label.toLowerCase().includes(search.toLowerCase()) ?
                        <Dropdown.Item className="dropdown-hover" key={index} onClick={() => { console.log(option); setValue(option) }}>
                            {option.label}
                        </Dropdown.Item>
                        : null
                    )}
                </div>
            </div>

        </Dropdown.Menu>
    </Dropdown>
    )

}