import React from "react";
import styled from "styled-components";

export const ConversationPoweredBy = styled.div`
  padding: 0px 16px;
  box-sizing: inherit;
`;

export const PoweredByWrapper = styled.div`
    position: relative;
    font-size: 12px;
    text-align: left;
    background: transparent;
    padding: 0px 0px 8px;
    border-bottom: 1px solid rgba(61, 61, 61, 0.1);
    box-sizing: inherit;
`;

export const PoweredByA = styled.a`
    color: ${props => props.theme.question};
    text-decoration: none;
    transition: opacity 0.3s ease 0s;
    border-radius: 3px;
    cursor: pointer;
    box-sizing: inherit;
    &:hover{
        opacity: 0.7;
        color: ${props => props.theme.question};
    }
`;


export default function PoweredBy(props) {
    return <ConversationPoweredBy>
        <PoweredByWrapper>
            <PoweredByA href="https://www.salessimplify.com" target={"_blank"}>
                Powered by Sales Simplify
            </PoweredByA>
        </PoweredByWrapper>
    </ConversationPoweredBy>
}