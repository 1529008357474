import React from "react";
import ChatWidget from './widget';
import Messenger from "./messenger/messenger";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, useRoutes, } from "react-router-dom";

const App = () => {
    const routes = useRoutes([
        { path: "/c/:id", element: <ChatWidget /> },
        { path: "/embed/:id", element: <ChatWidget /> },
        { path: "/leadbot", element: <Messenger /> },
    ]);
    return routes;
};
export function BaseRoutes() {
    return (
        <Router >
            <App />
        </Router>
    );
}