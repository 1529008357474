import React from "react";
import {
    ThankYouScreenWrapper, TYSContentWrapper, TYSContentWrapper1,
    TYSContentWrapper2, PoweredByA, PoweredByContainer, PoweredByWrapped
} from "./styled";

export default function ThankYouScreen(props) {
    return <ThankYouScreenWrapper>
        <TYSContentWrapper>
            <TYSContentWrapper1>
                <TYSContentWrapper2>
                    <span className="content-wrapper" dangerouslySetInnerHTML={{ "__html": props.data.descrption }}></span>
                </TYSContentWrapper2>

            </TYSContentWrapper1>
        </TYSContentWrapper>
        {props.showBranding && <PoweredByContainer>
            <PoweredByWrapped>
                Powered by <PoweredByA href="https://www.salessimplify.com">Sales Simplify</PoweredByA>
            </PoweredByWrapped>
        </PoweredByContainer>}
    </ThankYouScreenWrapper>
}