/* eslint-disable no-useless-escape */
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/;
const globalPhone = /[0-9]{1,18}/;

export const checkMessageType = (botQuestions, questionIndex) => {
    if (!botQuestions) return "";
    const question = botQuestions[questionIndex];
    if (!question) {
        return ""
    }
    return question.fieldId && question.fieldId.fieldInputType;

};

export const getOptions = (botQuestions, questionIndex) => {
    const question = botQuestions[questionIndex];
    if (!question) {
        return []
    }
    return question.fieldId && question.fieldId.options ? question.fieldId.options : []

};
export const validate = (questions, index, value) => {
    const question = questions[index];
    if (value) {
        //validation
        if (typeof value === 'string' && question.fieldId.validation === 'URL_VALIDATION' && !urlRegex.test(value)) {
            return { status: false, message: `Provided url doesn't look valid!` };
        } else if (typeof value === 'string' && question.fieldId.validation === 'PHONE_VALIDATION' && !globalPhone.test(value)) {
            return { status: false, message: `Provided phone doesn't look valid!` };
        } else if (typeof value === 'string' && question.fieldId.validation === 'EMAIL_VALIDATION' && !emailRegex.test(value)) {
            return { status: false, message: `Provided email doesn't look valid!` };
        } else if (typeof value === 'string' && question.fieldId.validation === 'Pin_VALIDATION' && !globalPhone.test(value)) {
            return { status: false, message: `Provided pin code doesn't look valid!` };
        }
        return { status: true, message: "" }
    }
    else if (question.isMandatory && !value) {
        return { status: false, message: "" }
    }
    return { status: true, message: "" }

}

export const hexToLuma = (colour) => {
    const hex = colour.replace(/#/, '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    const value = [
        0.299 * r,
        0.587 * g,
        0.114 * b
    ].reduce((a, b) => a + b);

    if (value > 186) return "#000000"; else return "#ffffff";
};

export const setOpacity = (color, opacity) => {
    const _opacity = Math.round(Math.min(Math.max(opacity || 0.1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}
