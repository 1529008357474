import styled from "styled-components";
import { hexToLuma } from "../widget/helper";

export const ThankYouScreenWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index:1;
`;


export const TYSContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 113px);
`;

export const TYSContentWrapper1 = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0%;
  padding: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
    align-items: center;
  transform-origin: left bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
`;
export const TYSContentWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 24px 4px;
  font-size: 16px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  max-width: calc(100%);
  color: ${props => props.theme.question};
`;

export const PoweredByContainer = styled.div`
  position: fixed;
  bottom: 15px;
  // right: 15px;
`;

export const PoweredByWrapped = styled.div`
  padding:7px 10px;
  font-size: 12px;
  background-color:${props => props.theme.question};
  color: ${props => hexToLuma(props.theme.question)};
  border-radius: 4px;
`;

export const PoweredByA = styled.a`
  font-size: 12px;
  background-color:${props => props.theme.question};
  color: ${props => hexToLuma(props.theme.question)};
  text-decoration: none;

  &:hover {
    color: ${props => hexToLuma(props.theme.question)};
    opacity: 0.9
  }
`