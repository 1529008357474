import React, { useState } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { country_select_options, timezone_select_options, currency_select_options } from "../../constants";
import { hexToLuma, setOpacity } from "../../containers/widget/helper";
import "./styles.css";
import SelectDropdown from "./dropdown";

export const ConversationFooter = styled.div`
  box-sizing: inherit;
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  position: relative;
  overflow: inherit;
  transition: max-height 500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, bottom 400ms cubic-bezier(0.19, 1, 0.22, 1) 200ms, opacity 400ms ease-out 180ms;
  bottom: 0px;
  opacity: 1;
  max-height:  336px;//74px;//var(--max-height);
`;

export const UserInputContainer = styled.div`
  width: 100%;
  box-sizing: inherit;
`;

export const UserInputWrapper = styled.div`
  padding: 0px 16px 16px;
  box-sizing: inherit;
`;
export const InputBox = styled.div`
  box-sizing: inherit;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
`;

export const InputWrapper = styled.form`
  display: flex;
  box-sizing: inherit;
`

export const SkipButton = styled.button`
display:none;
  // display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  transition: opacity 0.2s ease 0s;
  border: 1px solid transparent;
  padding: 0px 10px;
  margin-right: 8px;
  color:  ${props => props.theme.answer};
  background: ${props => setOpacity(props.theme.answer, 0.1)};
`;
export const SkipButton2 = styled.span`
  width: 40px;
  height: 40px;
  `;
export const SendButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  padding: 0px;
  transition: opacity 0.2s ease 0s;
  width: 40px;
  margin-left: 8px;
  background: ${props => props.theme.button};
  border: 1px solid ${props => props.theme.button};
  color: ${props => hexToLuma(props.theme.button)};
`;
export const Input = styled.input`
  background: transparent;
  color:  ${props => props.theme.answer};
  font-size: 16px;
  font-family: inherit;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0px 12px;
  box-shadow: ${props => props.theme.answer} 0px 0px 0px 1px inset;
  border: none;
  border-radius: 4px;
  line-height: 20px;
  outline: none;
  transition: all 0.3s ease 0s;
  appearance: none;
`;
export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;

export const ChoiceOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 320px;
`;

export const ListScroller = styled.div`
  overflow-x: auto;
  display:flex;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 24px;
  scroll-padding-right: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const ListRootColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
`;
export const ListRootRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
`;
export const ListButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 8px;
  padding: 12px 15px;
  line-height: 20px;
  // min-height: 44px;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  color: ${props => props.theme.answer};;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  width: fit-content;
  max-width: 340px;
  border: none;
  box-shadow:${props => props.theme.answer} 0px 0px 0px 1px inset;
  background: ${props => setOpacity(props.theme.answer, 0.1)};
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  &:hover{
    background: ${props => setOpacity(props.theme.answer, 0.15)};
  }
`;

export const ListRowButtons = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 0px 0px 8px;
  padding: 0px 12px;
  line-height: 20px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  color:${props => props.theme.answer};
  transition: all 0.2s ease 0s;
  cursor: pointer;
  width: fit-content;
  max-width: 340px;
  border: none;
  box-shadow: ${props => props.theme.answer} 0px 0px 0px 1px inset;
  background: ${props => setOpacity(props.theme.answer)};;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  flex: 0 0 auto;
`;

export default function Footer(props) {

  const [inputData, setInputData] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("")

  const onInputClick = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      ((inputData.trim()).length) && props.onInputSubmit(inputData, code);
      setInputData("");
      setPhone("");
      setCode("");
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.onInputSubmit(inputData.trim(), code)
    setInputData("");
    setPhone("");
    setCode("")
  }

  const renderInput = () => {
    switch (props.fieldType) {
      case "MULTI_PHONE_INPUT":
        return <InputWrapper>
          <SkipButton>Skip</SkipButton>
          <InputInner style={{ "--answer": props.customisation.answer }}>
            <PhoneInput
              containerClass={"widget-ph-containerClass"}
              inputClass={"widget-ph-inputClass"}
              buttonClass={"widget-ph-buttonClass"}
              dropdownClass={"widget-ph-dropdownClass"}
              searchClass={"widget-ph-searchClass"}
              value={phone}
              enableSearch={true}
              disableSearchIcon={true}
              country={props.countryCode ? props.countryCode.toLowerCase() : "us"}
              onChange={(e) => {
                setPhone(e);
                setInputData(e);
              }}
              onKeyDown={onInputClick}
            />
          </InputInner>
          <SendButton onClick={(e) => handleClick(e)}>
            <i className="bi bi-send-fill"></i>
          </SendButton>
        </InputWrapper>;

      case "NUMBER":
        return <InputWrapper>
          <SkipButton>Skip</SkipButton>
          <InputInner>
            <Input
              type="number"
              value={inputData}
              onChange={(e) => setInputData(e.target.value)}
              onKeyDown={onInputClick}
              placeholder="+91 98765 54321"
            />
          </InputInner>
          <SendButton onClick={(e) => handleClick(e)}>
            <i className="bi bi-send-fill"></i>
          </SendButton>
        </InputWrapper>;

      case "DATE":
        return <InputWrapper>
          <SkipButton>Skip</SkipButton>
          <InputInner>
            <Input
              type="date"
              value={inputData}
              onChange={(e) => setInputData(e.target.value)}
              onKeyDown={onInputClick}
              placeholder="Send a message..."
            />
          </InputInner>
          <SendButton onClick={(e) => handleClick(e)}>
            <i className="bi bi-send-fill" ></i>
          </SendButton>
        </InputWrapper>;

      case "DATE_TIME":
        return <InputWrapper>
          <SkipButton>Skip</SkipButton>
          <InputInner>
            <Input
              type="datetime-local"
              value={inputData}
              onChange={(e) => setInputData(e.target.value)}
              onKeyDown={onInputClick}
              placeholder="Send a message..."
            />
          </InputInner>
          <SendButton onClick={(e) => handleClick(e)}>
            <i className="bi bi-send-fill"></i>
          </SendButton>
        </InputWrapper>
      case "TOGGLE_BUTTON":
        return <ChoiceOptionContainer>
          <ListScroller>
            <SkipButton2>&nbsp;</SkipButton2>
            <ListRootColumn>
              <ListButton onClick={() => props.onInputSubmit("Yes")}>Yes</ListButton>
              <ListButton onClick={() => props.onInputSubmit("No")}>No</ListButton>
            </ListRootColumn>
          </ListScroller>
        </ChoiceOptionContainer>
      case "CHECKBOX":
      case "RADIO":
      case "SELECT":
        return <ChoiceOptionContainer>
          <ListScroller>
            <SkipButton2>&nbsp;</SkipButton2>
            <ListRootColumn>
              {props.options.map((each, index) => {
                return <ListButton key={index} onClick={() => props.onInputSubmit(each)}>{each}</ListButton>
              })}
            </ListRootColumn>
          </ListScroller>
        </ChoiceOptionContainer>;

      case "CURRENCY_SELECT":
        return <InputWrapper>
          <SkipButton>Skip</SkipButton>
          <InputInner style={{ "--answer": props.customisation.answer, "--background": props.customisation.background }}>
            <SelectDropdown
              label={"Currency"}
              list={currency_select_options}
              value={inputData}
              setValue={(value) => { setInputData(value.label); setCode(value.code) }}
            />
          </InputInner>
          <SendButton onClick={(e) => handleClick(e)}>
            <i className="bi bi-send-fill" ></i>
          </SendButton>
        </InputWrapper>
      case "TIMEZONE_SELECT":
        return <InputWrapper>
          <SkipButton>Skip</SkipButton>
          <InputInner style={{ "--answer": props.customisation.answer, "--background": props.customisation.background }}>
            <SelectDropdown
              label={"Timezone"}
              list={timezone_select_options}
              value={inputData}
              setValue={(value) => { setInputData(value.label); }}
            />
          </InputInner>
          <SendButton onClick={(e) => handleClick(e)}>
            <i className="bi bi-send-fill" ></i>
          </SendButton>
        </InputWrapper>
      case "COUNTRY_SELECT":
        return <InputWrapper>
          <SkipButton>Skip</SkipButton>
          <InputInner style={{ "--answer": props.customisation.answer, "--background": props.customisation.background }}>
            <SelectDropdown
              label={"Country"}
              list={country_select_options}
              value={inputData}
              setValue={(value) => { setInputData(value.label); }}
            />
          </InputInner>
          <SendButton onClick={(e) => handleClick(e)}>
            <i className="bi bi-send-fill" ></i>
          </SendButton>
        </InputWrapper>
      case "":
        return <></>;
      default:
        return <InputWrapper>
          <SkipButton>Skip</SkipButton>
          <InputInner>
            <Input
              type="text"
              value={inputData}
              onChange={(e) => setInputData(e.target.value)}
              onKeyDown={onInputClick}
              placeholder="Send a message..."
            />
          </InputInner>
          <SendButton onClick={(e) => handleClick(e)}>
            <i className="bi bi-send-fill" ></i>
          </SendButton>
        </InputWrapper>
    }
  }

  return <ConversationFooter>
    <UserInputContainer>
      <UserInputWrapper>
        <InputBox>
          {renderInput()}
        </InputBox>
      </UserInputWrapper>
    </UserInputContainer>
  </ConversationFooter>
}