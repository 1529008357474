import React, { useEffect, useState } from "react";
import _ from "lodash";
import { WidgetContainer, AppBackground, AppFrame, Conversation } from "./widget.sytled";
import PoweredBy from "../../components/powered-by";
import Footer from "../../components/footer"
import MessageList from "./message-list";
import { useParams } from "react-router-dom";
import { getLeadbotDetails, saveResponse, getGeoLocation, getGeoLocationByRangerAPI } from "./api.widget";
import { checkMessageType, getOptions, validate } from "./helper";
import { ThemeProvider } from "styled-components";
import WelcomeScreen from "../welcome-screen";
import ThankYouScreen from "../thankyou-screen"
import NotAvailable from "../../components/not-available/not-available.landing-page";
import Loading from "../../components/loading/loading";

export default function Widget(props) {
    const [leadBotId, setLeadBotId] = useState("")
    const [conversation, setConversation] = useState([]);
    const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
    const [showChatHistoryScreen, setShowChatHistoryScreen] = useState(false);
    const [showThankYouScreen, setShowThankYouScreen] = useState(false);

    const [welcomeScreenData, setWelcomeScreenData] = useState({});
    const [questions, setQuestions] = useState([]);
    const [thankYouScreenData, setThankYouScreenData] = useState({})
    const [customisation, setCustomisation] = useState({});
    const [showBranding, setShowBranding] = useState(true)

    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentQuestionId, setCurrentQuestionId] = useState("");
    const [response, setResponse] = useState({});
    const [theme, setTheme] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [countryCode, setCountryCode] = useState("us");
    const [showTyping, setShowTyping] = useState(false);
    const [ipData, setIpData] = useState({});

    const params = useParams();

    useEffect(() => {
        if (params.id)
            setLeadBotId(params.id)
    }, [params.id])

    useEffect(() => {
        if (props.leadBotId)
            setLeadBotId(props.leadBotId)
    }, [props.leadBotId])

    useEffect(() => {
        async function fetchData() {
            try {
                const leadBotData = await getLeadbotDetails(leadBotId);
                if (!_.isEmpty(leadBotData.data)) {
                    setIsActive(leadBotData.data.isEnabled);
                    if (leadBotData.data.name) {
                        document.title = leadBotData.data.name;
                    }

                    const welcomeScreen = leadBotData.data.welcomeScreen;
                    setWelcomeScreenData(welcomeScreen);
                    setThankYouScreenData(leadBotData.data.thankYouScreen)
                    setCustomisation(leadBotData.data.customization);
                    setQuestions(leadBotData.data.questionsScreen);
                    setTheme({
                        question: leadBotData.data.customization.question,
                        answer: leadBotData.data.customization.answer,
                        button: leadBotData.data.customization.button,
                        background: leadBotData.data.customization.background,
                        backgroundImage: leadBotData.data.customization.backgroundImage
                    })
                    if (welcomeScreen.isEnabled) {
                        setShowWelcomeScreen(true)
                        setShowChatHistoryScreen(false)
                        setShowThankYouScreen(false)
                    }
                    else {
                        onGetStarted()
                    }

                    if (typeof leadBotData.data.customization.isBrandingEnabled === "boolean" && !leadBotData.data.customization.isBrandingEnabled) {
                        setShowBranding(false);
                    }
                    else {
                        setShowBranding(true)
                    }

                    setLoading(false);
                }
                else {
                    setLoading(false);
                    setIsActive(false);
                }
            }
            catch (err) {
                setLoading(false);
                setIsActive(false);
                console.log("err", err)
            }
        }
        if (leadBotId) fetchData();
    }, [leadBotId]);

    useEffect(() => {
        async function geoLocation() {
            try {
                const leadBotData = await getGeoLocation();
                if (leadBotData.country_code) {
                    setCountryCode(leadBotData.country_code)
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        geoLocation()
    }, [])

    useEffect(() => {
        async function geoLocation() {
            try {
                const ipData = await getGeoLocationByRangerAPI();
                setIpData(ipData)
            }
            catch (err) {
                console.log(err);
            }
        }
        geoLocation();
    }, [])

    useEffect(() => {
        if (questions.length > 0 && questions[currentIndex] && conversation.length === 0) {
            const _con = [];
            _con.push({
                _id: questions[currentIndex]._id,
                message: questions[currentIndex].question,
                messageType: checkMessageType(questions, currentIndex),
                from: "bot",
                options: getOptions(questions, currentIndex)
            })
            setConversation(_con);
            setCurrentQuestionId(questions[currentIndex]._id)
        }
    }, [questions, currentIndex, conversation]);

    const onGetStarted = () => {
        setShowWelcomeScreen(false)
        setShowChatHistoryScreen(true)
        setShowThankYouScreen(false)
    };

    const updateDataByGeoLocation = async (data) => {
        if (ipData && ipData.geoIpData) {
            if (!data.hasOwnProperty("billingCity") && ipData.geoIpData.cityName) {
                data.billingCity = ipData.geoIpData.cityName;
            }
            if (!data.hasOwnProperty("billingState") && ipData.geoIpData.regionName) {
                data.billingState = ipData.geoIpData.regionName;
            }
            if (!data.hasOwnProperty("billingCountry") && ipData.geoIpData.countryName) {
                data.billingCountry = ipData.geoIpData.countryName;
            }
            if (!data.hasOwnProperty("billingZipCode") && ipData.geoIpData.zipCode) {
                data.billingZipCode = ipData.geoIpData.zipCode;
            }
            // if(!data.hasOwnProperty("timezone")){
            //     data.timezone = ipData.geoIpData.timeZone;
            // }

        }
        return data;
    }

    const updateResponse = (input, index, extra, newResponse) => {
        const question = questions[index];
        if (!question) return newResponse;
        if (_.isEmpty(question.fieldId)) return newResponse;
        const customResponse = { ...newResponse };
        if (question.fieldId.fieldGroup === "CUSTOM") {
            if (question.fieldId.fieldInputType === "TOGGLE_BUTTON") {
                customResponse[`customFields.${question.fieldId.apiKeyName}`] = input === "Yes";
            }
            else if (["MULTI_EMAIL_INPUT", "MULTI_PHONE_INPUT", "CHECKBOX"].includes(question.fieldId.fieldInputType)) {
                if (Array.isArray(input)) {
                    customResponse[`customFields.${question.fieldId.apiKeyName}`] = input;
                }
                else {
                    customResponse[`customFields.${question.fieldId.apiKeyName}`] = [input];
                }
            }
            else {
                customResponse[`customFields.${question.fieldId.apiKeyName}`] = input;
            }
        }
        else {
            if (question.fieldId.fieldInputType === "CURRENCY_SELECT") {
                customResponse[`${question.fieldId.apiKeyName}`] = extra;
            }
            else if (question.fieldId.fieldInputType === "TOGGLE_BUTTON") {
                customResponse[`${question.fieldId.apiKeyName}`] = input === "Yes";
            }
            else if (["MULTI_EMAIL_INPUT", "MULTI_PHONE_INPUT", "CHECKBOX"].includes(question.fieldId.fieldInputType)) {
                if (Array.isArray(input)) {
                    customResponse[`${question.fieldId.apiKeyName}`] = input;
                } else {
                    customResponse[`${question.fieldId.apiKeyName}`] = [input];
                }
            }
            else {
                customResponse[`${question.fieldId.apiKeyName}`] = input;
            }
        }
        setResponse(customResponse);

        // console.log(customResponse, "customResponse");
        return customResponse;
    }

    const handleHiddenFieldData = (index, _response) => {
        const q = questions[index + 1];
        if (q && q.isHidden) {
            const response = updateResponse(q.defaultAnswer, index + 1, "", _response);
            return handleHiddenFieldData(index + 1, response)
        }
        else {
            return { index, _response };
        }
    }

    const onInputSubmit = async (input, extra) => {
        const conversationMessage = [];
        let _response = { ...response }, defaultCurrentIndex = currentIndex;

        const validationResult = validate(questions, currentIndex, input);
        if (!validationResult.status) {
            setErrorMessage(validationResult.message)
            return;
        }

        if (input) {
            conversationMessage.push({
                _id: "",
                message: input,
                messageType: "text-message",
                from: "user",
                time: new Date(),
                options: []
            });

            _response = updateResponse(input, currentIndex, extra, _response);
        };

        if (questions.length - 1 > currentIndex) {
            let updated = handleHiddenFieldData(currentIndex, _response);
            _response = updated._response
            defaultCurrentIndex = updated.index;
            // const q = questions[currentIndex + 1];
            // if (q.isHidden) {
            //     _response = updateResponse(q.defaultAnswer, currentIndex + 1, "", _response);
            //     defaultCurrentIndex = currentIndex + 1;
            // }
        }
        // console.log(_response, "_response")

        if (questions.length - 1 > defaultCurrentIndex) {
            conversationMessage.unshift({
                _id: questions[defaultCurrentIndex + 1]._id,
                message: questions[defaultCurrentIndex + 1].question,
                messageType: checkMessageType(questions, defaultCurrentIndex + 1),
                from: "bot",
                options: getOptions(questions, defaultCurrentIndex + 1)
            });
            setCurrentQuestionId(questions[defaultCurrentIndex + 1]._id)
        }
        else {
            //end of flow
            const updatedResponse = await updateDataByGeoLocation(_response);
            // console.log(updatedResponse, "updatedResponse")
            saveResponse(leadBotId, updatedResponse);
            if (thankYouScreenData.isEnabled) {
                setShowWelcomeScreen(false)
                setShowChatHistoryScreen(false)
                setShowThankYouScreen(true)
            }
        }
        setErrorMessage("");
        setCurrentIndex(defaultCurrentIndex + 1);

        //typing logic
        if (conversationMessage.length === 2) {
            const oldConv = [...conversation];
            setShowTyping(true);
            setConversation([conversationMessage[1], ...oldConv]);
            setTimeout(() => {
                setConversation([...conversationMessage, ...oldConv]);
                setShowTyping(false);
            }, 1000);
        }
        else {
            setConversation([...conversationMessage, ...conversation]);
        }
    }

    return <WidgetContainer>
        {loading ? <Loading /> : !isActive ? <NotAvailable /> :
            <ThemeProvider theme={theme}>
                <Conversation>
                    {showWelcomeScreen && <WelcomeScreen onGetStarted={onGetStarted} data={welcomeScreenData} />}
                    {showChatHistoryScreen && <AppFrame>
                        {showBranding && <PoweredBy />}
                        <MessageList
                            showTyping={showTyping}
                            errorMessage={errorMessage}
                            conversation={conversation}
                            questions={questions}
                            customisation={customisation}
                            currentQuestionId={currentQuestionId}
                            setConversation={setConversation}
                        />
                        <Footer
                            countryCode={countryCode}
                            options={getOptions(questions, currentIndex)}
                            customisation={customisation}
                            fieldType={checkMessageType(questions, currentIndex)}
                            onInputSubmit={onInputSubmit}
                        />
                    </AppFrame>}
                    {showThankYouScreen && <ThankYouScreen showBranding={showBranding} data={thankYouScreenData} />}
                    <AppBackground />
                </Conversation>
            </ThemeProvider>
        }
    </WidgetContainer>
}