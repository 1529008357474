export const currency_select_options = [
  {

    label: "US Dollar",
    value: "US Dollar",
    code: "USD",

  },
  {
    label: "Canadian Dollar",
    value: "Canadian Dollar",
    code: "CAD",

  },
  {

    label: "Euro",
    value: "Euro",
    code: "EUR",

  },
  {

    label: "United Arab Emirates Dirham",
    value: "United Arab Emirates Dirham",
    code: "AED",

  },
  {
    label: "Afghan Afghani",
    value: "Afghan Afghani",
    code: "AFN",

  },
  {

    label: "Albanian Lek",
    value: "Albanian Lek",
    code: "ALL",

  },
  {

    label: "Armenian Dram",
    value: "Armenian Dram",
    code: "AMD",

  },
  {

    label: "Argentine Peso",
    value: "Argentine Peso",
    code: "ARS",

  },
  {

    label: "Australian Dollar",
    value: "Australian Dollar",
    code: "AUD",

  },
  {

    label: "Azerbaijani Manat",
    value: "Azerbaijani Manat",
    code: "AZN",

  },
  {

    label: "Bosnia-Herzegovina Convertible Mark",
    value: "Bosnia-Herzegovina Convertible Mark",
    code: "BAM",

  },
  {

    label: "Bangladeshi Taka",
    value: "Bangladeshi Taka",
    code: "BDT",

  },
  {

    label: "Bulgarian Lev",
    value: "Bulgarian Lev",
    code: "BGN",

  },
  {


    label: "Bahraini Dinar",
    value: "Bahraini Dinar",
    code: "BHD",

  },
  {


    label: "Burundian Franc",
    value: "Burundian Fran",
    code: "BIF",

  },
  {


    label: "Brunei Dollar",
    value: "Brunei Dollar",
    code: "BND",

  },
  {


    label: "Bolivian Boliviano",
    value: "Bolivian Boliviano",
    code: "BOB",

  },
  {


    label: "Brazilian Real",
    value: "Brazilian Real",
    code: "BRL",

  },
  {

    label: "Botswanan Pula",
    value: "Botswanan Pula",
    code: "BWP",

  },
  {

    label: "Belarusian Ruble",
    value: "Belarusian Ruble",
    code: "BYN",

  },
  {
    label: "Belize Dollar",
    value: "Belize Dollar",
    code: "BZD",

  },
  {
    label: "Congolese Franc",
    value: "Congolese Franc",
    code: "CDF",

  },
  {
    label: "Swiss Franc",
    value: "Swiss Franc",
    code: "CHF",

  },
  {
    label: "Chilean Peso",
    value: "Chilean Peso",
    code: "CLP",

  },
  {
    label: "Chinese Yuan",
    value: "Chinese Yuan",
    code: "CNY",

  },
  {
    label: "Colombian Peso",
    value: "Colombian Peso",
    code: "COP",

  },
  {
    label: "Costa Rican Colon",
    value: "Costa Rican Colon",
    code: "CRC",

  },
  {

    label: "Cape Verdean Escudo",
    value: "Cape Verdean Escudo",
    code: "CVE",

  },
  {
    label: "Czech Republic Koruna",
    value: "Czech Republic Koruna",
    code: "CZK",

  },
  {

    label: "Djiboutian Franc",
    value: "Djiboutian Franc",
    code: "DJF",

  },
  {
    label: "Danish Krone",
    value: "Danish Krone",
    code: "DKK",

  },
  {
    label: "Dominican Peso",
    value: "Dominican Peso",
    code: "DOP",

  },
  {
    label: "Algerian Dinar",
    value: "Algerian Dinar",
    code: "DZD",

  },
  {
    label: "Estonian Kroon",
    value: "Estonian Kroon",
    code: "EEK",

  },
  {
    label: "Egyptian Pound",
    value: "Egyptian Pound",
    code: "EGP",

  },
  {
    label: "Eritrean Nakfa",
    value: "Eritrean Nakfa",
    code: "ERN",

  },
  {
    label: "Ethiopian Birr",
    value: "Ethiopian Birr",
    code: "ETB",

  },
  {
    label: "British Pound Sterling",
    value: "British Pound Sterling",
    code: "GBP",

  },
  {

    label: "Georgian Lari",
    value: "Georgian Lari",
    code: "GEL",

  },
  {
    label: "Ghanaian Cedi",
    value: "Ghanaian Cedi",
    code: "GHS",

  },
  {
    label: "Guinean Franc",
    value: "Guinean Franc",
    code: "GNF",

  },
  {
    label: "Guatemalan Quetzal",
    value: "Guatemalan Quetzal",
    code: "GTQ",

  },
  {
    label: "Hong Kong Dollar",
    value: "Hong Kong Dollar",
    code: "HKD",

  },
  {
    label: "Honduran Lempira",
    value: "Honduran Lempira",
    code: "HNL",

  },
  {
    label: "Croatian Kuna",
    value: "Croatian Kuna",
    code: "HRK",

  },
  {
    label: "Hungarian Forint",
    value: "Hungarian Forint",
    code: "HUF",

  },
  {
    label: "Indonesian Rupiah",
    value: "Indonesian Rupiah",
    code: "IDR",

  },
  {

    label: "Israeli New Shekel",
    value: "Israeli New Shekel",
    code: "ILS",

  },
  {
    label: "Indian Rupee",
    value: "Indian Rupee",
    code: "INR",

  },
  {
    label: "Iraqi Dinar",
    value: "Iraqi Dinar",
    code: "IQD",

  },
  {
    label: "Iranian Rial",
    value: "Iranian Rial",
    code: "IRR",

  },
  {
    label: "Icelandic króna",
    value: "Icelandic króna",
    code: "ISK",

  },
  {
    label: "Jamaican Dollar",
    value: "Jamaican Dollar",
    code: "JMD",

  },
  {
    label: "Jordanian Dinar",
    value: "Jordanian Dinar",
    code: "JOD",

  },
  {
    label: "Japanese Yen",
    value: "Japanese Yen",
    code: "JPY",

  },
  {
    label: "Kenyan Shilling",
    value: "Kenyan Shilling",
    code: "KES",

  },
  {
    label: "Cambodian Riel",
    value: "Cambodian Riel",
    code: "KHR",

  },
  {
    label: "Comorian Franc",
    value: "Comorian Franc",
    code: "KMF",

  },
  {
    label: "South Korean Won",
    value: "South Korean Won",
    code: "KRW",

  },
  {
    label: "Kuwaiti Dinar",
    value: "Kuwaiti Dinar",
    code: "KWD",

  },
  {

    label: "Kazakhstani Tenge",
    value: "Kazakhstani Tenge",
    code: "KZT",

  },
  {

    label: "Lebanese Pound",
    value: "Lebanese Pound",
    code: "LBP",

  },
  {

    label: "Sri Lankan Rupee",
    value: "Sri Lankan Rupee",
    code: "LKR",

  },
  {
    label: "Lithuanian Litas",
    value: "Lithuanian Litas",
    code: "LTL",

  },
  {
    label: "Latvian Lats",
    value: "Latvian Lats",
    code: "LVL",

  },
  {
    label: "Libyan Dinar",
    value: "Libyan Dinar",
    code: "LYD",

  },
  {
    label: "Moroccan Dirham",
    value: "Moroccan Dirham",
    code: "MAD",

  },
  {
    label: "Moldovan Leu",
    value: "Moldovan Leu",
    code: "MDL",

  },
  {
    label: "Malagasy Ariary",
    value: "Malagasy Ariary",
    code: "MGA",

  },
  {
    label: "Macedonian Denar",
    value: "Macedonian Denar",
    code: "MKD",

  },
  {

    label: "Myanmar Kyat",
    value: "Myanmar Kyat",
    code: "MMK",

  },
  {

    label: "Macanese Pataca",
    value: "Macanese Pataca",
    code: "MOP",

  },
  {

    label: "Mauritian Rupee",
    value: "Mauritian Rupee",
    code: "MUR",

  },
  {
    label: "Mexican Peso",
    value: "Mexican Peso",
    code: "MXN",

  },
  {
    label: "Malaysian Ringgit",
    value: "Malaysian Ringgit",
    code: "MYR",

  },
  {
    label: "Mozambican Metical",
    value: "Mozambican Metical",
    code: "MZN",

  },
  {

    label: "Namibian Dollar",
    value: "Namibian Dollar",
    code: "NAD",

  },
  {

    label: "Nigerian Naira",
    value: "Nigerian Naira",
    code: "NGN",

  },
  {

    label: "Nicaraguan Cordoba",
    value: "Nicaraguan Cordoba",
    code: "NIO",

  },
  {
    label: "Norwegian Krone",
    value: "Norwegian Krone",
    code: "NOK",

  },
  {
    label: "Nepalese Rupee",
    value: "Nepalese Rupee",
    code: "NPR",

  },
  {
    label: "New Zealand Dollar",
    value: "New Zealand Dollar",
    code: "NZD",

  },
  {
    label: "Omani Rial",
    value: "Omani Rial",
    code: "OMR",

  },
  {
    label: "Panamanian Balboa",
    value: "Panamanian Balboa",
    code: "PAB",

  },
  {
    label: "Peruvian Nuevo Sol",
    value: "Peruvian Nuevo Sol",
    code: "PEN",

  },
  {
    label: "Philippine Peso",
    value: "Philippine Peso",
    code: "PHP",

  },
  {
    label: "Pakistani Rupee",
    value: "Pakistani Rupee",
    code: "PKR",

  },
  {
    label: "Polish Zloty",
    value: "Polish Zloty",
    code: "PLN",

  },
  {
    label: "Paraguayan Guarani",
    value: "Paraguayan Guarani",
    code: "PYG",

  },
  {
    label: "Qatari Rial",
    value: "Qatari Rial",
    code: "QAR",

  },
  {
    label: "Romanian Leu",
    value: "Romanian Leu",
    code: "RON",

  },
  {
    label: "Serbian Dinar",
    value: "Serbian Dinar",
    code: "RSD",

  },
  {
    label: "Russian Ruble",
    value: "Russian Ruble",
    code: "RUB",

  },
  {
    label: "Rwandan Franc",
    value: "Rwandan Franc",
    code: "RWF",

  },
  {
    label: "Saudi Riyal",
    value: "Saudi Riyal",
    code: "SAR",

  },
  {
    label: "Sudanese Pound",
    value: "Sudanese Pound",
    code: "SDG",

  },
  {
    label: "Swedish Krona",
    value: "Swedish Krona",
    code: "SEK",

  },
  {
    label: "Singapore Dollar",
    value: "Singapore Dollar",
    code: "SGD",

  },
  {
    label: "Somali Shilling",
    value: "Somali Shilling",
    code: "SOS",

  },
  {

    label: "Syrian Pound",
    value: "Syrian Pound",
    code: "SYP",

  },
  {
    label: "Thai Baht",
    value: "Thai Baht",
    code: "THB",

  },
  {
    label: "Tunisian Dinar",
    value: "Tunisian Dinar",
    code: "TND",

  },
  {
    label: "Tongan paʻanga",
    value: "Tongan paʻanga",
    code: "TOP",

  },
  {
    label: "Turkish Lira",
    value: "Turkish Lira",
    code: "TRY",

  },
  {
    label: "Trinidad and Tobago Dollar",
    value: "Trinidad and Tobago Dollar",
    code: "TTD",

  },
  {
    label: "New Taiwan Dollar",
    value: "New Taiwan Dollar",
    code: "TWD",

  },
  {
    label: "Tanzanian Shilling",
    value: "Tanzanian Shilling",
    code: "TZS",

  },
  {

    label: "Ukrainian Hryvnia",
    value: "Ukrainian Hryvnia",
    code: "UAH",

  },
  {
    label: "Ugandan Shilling",
    value: "Ugandan Shilling",
    code: "UGX",

  },
  {

    label: "Uruguayan Peso",
    value: "Uruguayan Peso",
    code: "UYU",

  },
  {

    label: "Uzbekistan Som",
    value: "Uzbekistan Som",
    code: "UZS",

  },
  {
    label: "Venezuelan Bolivar",
    value: "Venezuelan Bolivar",
    code: "VEF",

  },
  {
    label: "Vietnamese Dong",
    value: "Vietnamese Dong",
    code: "VND",

  },
  {
    label: "CFA Franc BEAC",
    value: "CFA Franc BEAC",
    code: "XAF",

  },
  {
    label: "CFA Franc BCEAO",
    value: "CFA Franc BCEAO",
    code: "XOF",

  },
  {
    label: "Yemeni Rial",
    value: "Yemeni Rial",
    code: "YER",

  },
  {
    label: "South African Rand",
    value: "South African Rand",
    code: "ZAR",

  },
  {

    label: "Zambian Kwacha",
    value: "Zambian Kwacha",
    code: "ZMK",

  },
  {
    label: "Zimbabwean Dollar",
    value: "Zimbabwean Dollar",
    code: "ZWL",

  }
]


export const country_select_options = [
  {
    value: "Afghanistan",
    label: "Afghanistan",
    code: "AF"
  },
  {
    value: "Åland Islands",
    label: "Åland Islands",
    code: "AX"
  },
  {
    value: "Albania",
    label: "Albania",
    code: "AL"
  },
  {
    value: "Algeria",
    label: "Algeria",
    code: "DZ"
  },
  {
    value: "American Samoa",
    label: "American Samoa",
    code: "AS"
  },
  {
    value: "AndorrA",
    label: "AndorrA",
    code: "AD"
  },
  {
    value: "Angola",
    label: "Angola",
    code: "AO"
  },
  {
    value: "Anguilla",
    label: "Anguilla",
    code: "AI"
  },
  {
    value: "Antarctica",
    label: "Antarctica",
    code: "AQ"
  },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
    code: "AG"
  },
  {
    value: "Argentina",
    label: "Argentina",
    code: "AR"
  },
  {
    value: "Armenia",
    label: "Armenia",
    code: "AM"
  },
  {
    value: "Aruba",
    label: "Aruba",
    code: "AW"
  },
  {
    value: "Australia",
    label: "Australia",
    code: "AU"
  },
  {
    value: "Austria",
    label: "Austria",
    code: "AT"
  },
  {
    value: "Azerbaijan",
    label: "Azerbaijan",
    code: "AZ"
  },
  {
    value: "Bahamas",
    label: "Bahamas",
    code: "BS"
  },
  {
    value: "Bahrain",
    label: "Bahrain",
    code: "BH"
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
    code: "BD"
  },
  {
    value: "Barbados",
    label: "Barbados",
    code: "BB"
  },
  {
    value: "Belarus",
    label: "Belarus",
    code: "BY"
  },
  {
    value: "Belgium",
    label: "Belgium",
    code: "BE"
  },
  {
    value: "Belize",
    label: "Belize",
    code: "BZ"
  },
  {
    value: "Benin",
    label: "Benin",
    code: "BJ"
  },
  {
    value: "Bermuda",
    label: "Bermuda",
    code: "BM"
  },
  {
    value: "Bhutan",
    label: "Bhutan",
    code: "BT"
  },
  {
    value: "Bolivia",
    label: "Bolivia",
    code: "BO"
  },
  {
    value: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
    code: "BA"
  },
  {
    value: "Botswana",
    label: "Botswana",
    code: "BW"
  },
  {
    value: "Bouvet Island",
    label: "Bouvet Island",
    code: "BV"
  },
  {
    value: "Brazil",
    label: "Brazil",
    code: "BR"
  },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
    code: "IO"
  },
  {
    value: "Brunei Darussalam",
    label: "Brunei Darussalam",
    code: "BN"
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
    code: "BG"
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
    code: "BF"
  },
  {
    value: "Burundi",
    label: "Burundi",
    code: "BI"
  },
  {
    value: "Cambodia",
    label: "Cambodia",
    code: "KH"
  },
  {
    value: "Cameroon",
    label: "Cameroon",
    code: "CM"
  },
  {
    value: "Canada",
    label: "Canada",
    code: "CA"
  },
  {
    value: "Cape Verde",
    label: "Cape Verde",
    code: "CV"
  },
  {
    value: "Cayman Islands",
    label: "Cayman Islands",
    code: "KY"
  },
  {
    value: "Central African Republic",
    label: "Central African Republic",
    code: "CF"
  },
  {
    value: "Chad",
    label: "Chad",
    code: "TD"
  },
  {
    value: "Chile",
    label: "Chile",
    code: "CL"
  },
  {
    value: "China",
    label: "China",
    code: "CN"
  },
  {
    value: "Christmas Island",
    label: "Christmas Island",
    code: "CX"
  },
  {
    value: "Cocos (Keeling) Islands",
    label: "Cocos (Keeling) Islands",
    code: "CC"
  },
  {
    value: "Colombia",
    label: "Colombia",
    code: "CO"
  },
  {
    value: "Comoros",
    label: "Comoros",
    code: "KM"
  },
  {
    value: "Congo",
    label: "Congo",
    code: "CG"
  },
  {
    value: "Congo, The Democratic Republic of the",
    label: "Congo, The Democratic Republic of the",
    code: "CD"
  },
  {
    value: "Cook Islands",
    label: "Cook Islands",
    code: "CK"
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
    code: "CR"
  },
  {
    value: "Cote D'Ivoire",
    label: "Cote D'Ivoire",
    code: "CI"
  },
  {
    value: "Croatia",
    label: "Croatia",
    code: "HR"
  },
  {
    value: "Cuba",
    label: "Cuba",
    code: "CU"
  },
  {
    value: "Cyprus",
    label: "Cyprus",
    code: "CY"
  },
  {
    value: "Czech Republic",
    label: "Czech Republic",
    code: "CZ"
  },
  {
    value: "Denmark",
    label: "Denmark",
    code: "DK"
  },
  {
    value: "Djibouti",
    label: "Djibouti",
    code: "DJ"
  },
  {
    value: "Dominica",
    label: "Dominica",
    code: "DM"
  },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
    code: "DO"
  },
  {
    value: "Ecuador",
    label: "Ecuador",
    code: "EC"
  },
  {
    value: "Egypt",
    label: "Egypt",
    code: "EG"
  },
  {
    value: "El Salvador",
    label: "El Salvador",
    code: "SV"
  },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
    code: "GQ"
  },
  {
    value: "Eritrea",
    label: "Eritrea",
    code: "ER"
  },
  {
    value: "Estonia",
    label: "Estonia",
    code: "EE"
  },
  {
    value: "Ethiopia",
    label: "Ethiopia",
    code: "ET"
  },
  {
    value: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
    code: "FK"
  },
  {
    value: "Faroe Islands",
    label: "Faroe Islands",
    code: "FO"
  },
  {
    value: "Fiji",
    label: "Fiji",
    code: "FJ"
  },
  {
    value: "Finland",
    label: "Finland",
    code: "FI"
  },
  {
    value: "France",
    label: "France",
    code: "FR"
  },
  {
    value: "French Guiana",
    label: "French Guiana",
    code: "GF"
  },
  {
    value: "French Polynesia",
    label: "French Polynesia",
    code: "PF"
  },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
    code: "TF"
  },
  {
    value: "Gabon",
    label: "Gabon",
    code: "GA"
  },
  {
    value: "Gambia",
    label: "Gambia",
    code: "GM"
  },
  {
    value: "Georgia",
    label: "Georgia",
    code: "GE"
  },
  {
    value: "Germany",
    label: "Germany",
    code: "DE"
  },
  {
    value: "Ghana",
    label: "Ghana",
    code: "GH"
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
    code: "GI"
  },
  {
    value: "Greece",
    label: "Greece",
    code: "GR"
  },
  {
    value: "Greenland",
    label: "Greenland",
    code: "GL"
  },
  {
    value: "Grenada",
    label: "Grenada",
    code: "GD"
  },
  {
    value: "Guadeloupe",
    label: "Guadeloupe",
    code: "GP"
  },
  {
    value: "Guam",
    label: "Guam",
    code: "GU"
  },
  {
    value: "Guatemala",
    label: "Guatemala",
    code: "GT"
  },
  {
    value: "Guernsey",
    label: "Guernsey",
    code: "GG"
  },
  {
    value: "Guinea",
    label: "Guinea",
    code: "GN"
  },
  {
    value: "Guinea-Bissau",
    label: "Guinea-Bissau",
    code: "GW"
  },
  {
    value: "Guyana",
    label: "Guyana",
    code: "GY"
  },
  {
    value: "Haiti",
    label: "Haiti",
    code: "HT"
  },
  {
    value: "Heard Island and Mcdonald Islands",
    label: "Heard Island and Mcdonald Islands",
    code: "HM"
  },
  {
    value: "Holy See (Vatican City State)",
    label: "Holy See (Vatican City State)",
    code: "VA"
  },
  {
    value: "Honduras",
    label: "Honduras",
    code: "HN"
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
    code: "HK"
  },
  {
    value: "Hungary",
    label: "Hungary",
    code: "HU"
  },
  {
    value: "Iceland",
    label: "Iceland",
    code: "IS"
  },
  {
    value: "India",
    label: "India",
    code: "IN"
  },
  {
    value: "Indonesia",
    label: "Indonesia",
    code: "ID"
  },
  {
    value: "Iran, Islamic Republic Of",
    label: "Iran, Islamic Republic Of",
    code: "IR"
  },
  {
    value: "Iraq",
    label: "Iraq",
    code: "IQ"
  },
  {
    value: "Ireland",
    label: "Ireland",
    code: "IE"
  },
  {
    value: "Isle of Man",
    label: "Isle of Man",
    code: "IM"
  },
  {
    value: "Israel",
    label: "Israel",
    code: "IL"
  },
  {
    value: "Italy",
    label: "Italy",
    code: "IT"
  },
  {
    value: "Jamaica",
    label: "Jamaica",
    code: "JM"
  },
  {
    value: "Japan",
    label: "Japan",
    code: "JP"
  },
  {
    value: "Jersey",
    label: "Jersey",
    code: "JE"
  },
  {
    value: "Jordan",
    label: "Jordan",
    code: "JO"
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
    code: "KZ"
  },
  {
    value: "Kenya",
    label: "Kenya",
    code: "KE"
  },
  {
    value: "Kiribati",
    label: "Kiribati",
    code: "KI"
  },
  {
    value: "Korea, Democratic People'S Republic of",
    label: "Korea, Democratic People'S Republic of",
    code: "KP"
  },
  {
    value: "Korea, Republic of",
    label: "Korea, Republic of",
    code: "KR"
  },
  {
    value: "Kuwait",
    label: "Kuwait",
    code: "KW"
  },
  {
    value: "Kyrgyzstan",
    label: "Kyrgyzstan",
    code: "KG"
  },
  {
    value: "Lao People'S Democratic Republic",
    label: "Lao People'S Democratic Republic",
    code: "LA"
  },
  {
    value: "Latvia",
    label: "Latvia",
    code: "LV"
  },
  {
    value: "Lebanon",
    label: "Lebanon",
    code: "LB"
  },
  {
    value: "Lesotho",
    label: "Lesotho",
    code: "LS"
  },
  {
    value: "Liberia",
    label: "Liberia",
    code: "LR"
  },
  {
    value: "Libyan Arab Jamahiriya",
    label: "Libyan Arab Jamahiriya",
    code: "LY"
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
    code: "LI"
  },
  {
    value: "Lithuania",
    label: "Lithuania",
    code: "LT"
  },
  {
    value: "Luxembourg",
    label: "Luxembourg",
    code: "LU"
  },
  {
    value: "Macao",
    label: "Macao",
    code: "MO"
  },
  {
    value: "Macedonia, The Former Yugoslav Republic of",
    label: "Macedonia, The Former Yugoslav Republic of",
    code: "MK"
  },
  {
    value: "Madagascar",
    label: "Madagascar",
    code: "MG"
  },
  {
    value: "Malawi",
    label: "Malawi",
    code: "MW"
  },
  {
    value: "Malaysia",
    label: "Malaysia",
    code: "MY"
  },
  {
    value: "Maldives",
    label: "Maldives",
    code: "MV"
  },
  {
    value: "Mali",
    label: "Mali",
    code: "ML"
  },
  {
    value: "Malta",
    label: "Malta",
    code: "MT"
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
    code: "MH"
  },
  {
    value: "Martinique",
    label: "Martinique",
    code: "MQ"
  },
  {
    value: "Mauritania",
    label: "Mauritania",
    code: "MR"
  },
  {
    value: "Mauritius",
    label: "Mauritius",
    code: "MU"
  },
  {
    value: "Mayotte",
    label: "Mayotte",
    code: "YT"
  },
  {
    value: "Mexico",
    label: "Mexico",
    code: "MX"
  },
  {
    value: "Micronesia, Federated States of",
    label: "Micronesia, Federated States of",
    code: "FM"
  },
  {
    value: "Moldova, Republic of",
    label: "Moldova, Republic of",
    code: "MD"
  },
  {
    value: "Monaco",
    label: "Monaco",
    code: "MC"
  },
  {
    value: "Mongolia",
    label: "Mongolia",
    code: "MN"
  },
  {
    value: "Montserrat",
    label: "Montserrat",
    code: "MS"
  },
  {
    value: "Morocco",
    label: "Morocco",
    code: "MA"
  },
  {
    value: "Mozambique",
    label: "Mozambique",
    code: "MZ"
  },
  {
    value: "Myanmar",
    label: "Myanmar",
    code: "MM"
  },
  {
    value: "Namibia",
    label: "Namibia",
    code: "NA"
  },
  {
    value: "Nauru",
    label: "Nauru",
    code: "NR"
  },
  {
    value: "Nepal",
    label: "Nepal",
    code: "NP"
  },
  {
    value: "Netherlands",
    label: "Netherlands",
    code: "NL"
  },
  {
    value: "Netherlands Antilles",
    label: "Netherlands Antilles",
    code: "AN"
  },
  {
    value: "New Caledonia",
    label: "New Caledonia",
    code: "NC"
  },
  {
    value: "New Zealand",
    label: "New Zealand",
    code: "NZ"
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
    code: "NI"
  },
  {
    value: "Niger",
    label: "Niger",
    code: "NE"
  },
  {
    value: "Nigeria",
    label: "Nigeria",
    code: "NG"
  },
  {
    value: "Niue",
    label: "Niue",
    code: "NU"
  },
  {
    value: "Norfolk Island",
    label: "Norfolk Island",
    code: "NF"
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
    code: "MP"
  },
  {
    value: "Norway",
    label: "Norway",
    code: "NO"
  },
  {
    value: "Oman",
    label: "Oman",
    code: "OM"
  },
  {
    value: "Pakistan",
    label: "Pakistan",
    code: "PK"
  },
  {
    value: "Palau",
    label: "Palau",
    code: "PW"
  },
  {
    value: "Palestinian Territory, Occupied",
    label: "Palestinian Territory, Occupied",
    code: "PS"
  },
  {
    value: "Panama",
    label: "Panama",
    code: "PA"
  },
  {
    value: "Papua New Guinea",
    label: "Papua New Guinea",
    code: "PG"
  },
  {
    value: "Paraguay",
    label: "Paraguay",
    code: "PY"
  },
  {
    value: "Peru",
    label: "Peru",
    code: "PE"
  },
  {
    value: "Philippines",
    label: "Philippines",
    code: "PH"
  },
  {
    value: "Pitcairn",
    label: "Pitcairn",
    code: "PN"
  },
  {
    value: "Poland",
    label: "Poland",
    code: "PL"
  },
  {
    value: "Portugal",
    label: "Portugal",
    code: "PT"
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
    code: "PR"
  },
  {
    value: "Qatar",
    label: "Qatar",
    code: "QA"
  },
  {
    value: "Reunion",
    label: "Reunion",
    code: "RE"
  },
  {
    value: "Romania",
    label: "Romania",
    code: "RO"
  },
  {
    value: "Russian Federation",
    label: "Russian Federation",
    code: "RU"
  },
  {
    value: "RWANDA",
    label: "RWANDA",
    code: "RW"
  },
  {
    value: "Saint Helena",
    label: "Saint Helena",
    code: "SH"
  },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
    code: "KN"
  },
  {
    value: "Saint Lucia",
    label: "Saint Lucia",
    code: "LC"
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
    code: "PM"
  },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
    code: "VC"
  },
  {
    value: "Samoa",
    label: "Samoa",
    code: "WS"
  },
  {
    value: "San Marino",
    label: "San Marino",
    code: "SM"
  },
  {
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
    code: "ST"
  },
  {
    value: "Saudi Arabia",
    label: "Saudi Arabia",
    code: "SA"
  },
  {
    value: "Senegal",
    label: "Senegal",
    code: "SN"
  },
  {
    value: "Serbia and Montenegro",
    label: "Serbia and Montenegro",
    code: "CS"
  },
  {
    value: "Seychelles",
    label: "Seychelles",
    code: "SC"
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
    code: "SL"
  },
  {
    value: "Singapore",
    label: "Singapore",
    code: "SG"
  },
  {
    value: "Slovakia",
    label: "Slovakia",
    code: "SK"
  },
  {
    value: "Slovenia",
    label: "Slovenia",
    code: "SI"
  },
  {
    value: "Solomon Islands",
    label: "Solomon Islands",
    code: "SB"
  },
  {
    value: "Somalia",
    label: "Somalia",
    code: "SO"
  },
  {
    value: "South Africa",
    label: "South Africa",
    code: "ZA"
  },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
    code: "GS"
  },
  {
    value: "Spain",
    label: "Spain",
    code: "ES"
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
    code: "LK"
  },
  {
    value: "Sudan",
    label: "Sudan",
    code: "SD"
  },
  {
    value: "Suriname",
    label: "Suriname",
    code: "SR"
  },
  {
    value: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
    code: "SJ"
  },
  {
    value: "Swaziland",
    label: "Swaziland",
    code: "SZ"
  },
  {
    value: "Sweden",
    label: "Sweden",
    code: "SE"
  },
  {
    value: "Switzerland",
    label: "Switzerland",
    code: "CH"
  },
  {
    value: "Syrian Arab Republic",
    label: "Syrian Arab Republic",
    code: "SY"
  },
  {
    value: "Taiwan, Province of China",
    label: "Taiwan, Province of China",
    code: "TW"
  },
  {
    value: "Tajikistan",
    label: "Tajikistan",
    code: "TJ"
  },
  {
    value: "Tanzania, United Republic of",
    label: "Tanzania, United Republic of",
    code: "TZ"
  },
  {
    value: "Thailand",
    label: "Thailand",
    code: "TH"
  },
  {
    value: "Timor-Leste",
    label: "Timor-Leste",
    code: "TL"
  },
  {
    value: "Togo",
    label: "Togo",
    code: "TG"
  },
  {
    value: "Tokelau",
    label: "Tokelau",
    code: "TK"
  },
  {
    value: "Tonga",
    label: "Tonga",
    code: "TO"
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
    code: "TT"
  },
  {
    value: "Tunisia",
    label: "Tunisia",
    code: "TN"
  },
  {
    value: "Turkey",
    label: "Turkey",
    code: "TR"
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
    code: "TM"
  },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
    code: "TC"
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
    code: "TV"
  },
  {
    value: "Uganda",
    label: "Uganda",
    code: "UG"
  },
  {
    value: "Ukraine",
    label: "Ukraine",
    code: "UA"
  },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
    code: "AE"
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
    code: "GB"
  },
  {
    value: "United States",
    label: "United States",
    code: "US"
  },
  {
    value: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
    code: "UM"
  },
  {
    value: "Uruguay",
    label: "Uruguay",
    code: "UY"
  },
  {
    value: "Uzbekistan",
    label: "Uzbekistan",
    code: "UZ"
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
    code: "VU"
  },
  {
    value: "Venezuela",
    label: "Venezuela",
    code: "VE"
  },
  {
    value: "Viet Nam",
    label: "Viet Nam",
    code: "VN"
  },
  {
    value: "Virgin Islands, British",
    label: "Virgin Islands, British",
    code: "VG"
  },
  {
    value: "Virgin Islands, U.S.",
    label: "Virgin Islands, U.S.",
    code: "VI"
  },
  {
    value: "Wallis and Futuna",
    label: "Wallis and Futuna",
    code: "WF"
  },
  {
    value: "Western Sahara",
    label: "Western Sahara",
    code: "EH"
  },
  {
    value: "Yemen",
    label: "Yemen",
    code: "YE"
  },
  {
    value: "Zambia",
    label: "Zambia",
    code: "ZM"
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
    code: "ZW"
  }
]

export const timezone_select_options = [
  {
    value: "Pacific/Niue",
    label: "Pacific/Niue"
  },
  {
    value: "Pacific/Midway",
    label: "Pacific/Midway"
  },
  {
    value: "Pacific/Pago_Pago",
    label: "Pacific/Pago_Pago"
  },
  {
    value: "Pacific/Rarotonga",
    label: "Pacific/Rarotonga"
  },
  {
    value: "America/Adak",
    label: "America/Adak"
  },
  {
    value: "Pacific/Honolulu",
    label: "Pacific/Honolulu"
  },
  {
    value: "Pacific/Tahiti",
    label: "Pacific/Tahiti"
  },
  {
    value: "Pacific/Marquesas",
    label: "Pacific/Marquesas"
  },
  {
    value: "America/Anchorage",
    label: "America/Anchorage"
  },
  {
    value: "Pacific/Gambier",
    label: "Pacific/Gambier"
  },
  {
    value: "America/Los_Angeles",
    label: "America/Los_Angeles"
  },
  {
    value: "America/Tijuana",
    label: "America/Tijuana"
  },
  {
    value: "America/Vancouver",
    label: "America/Vancouver"
  },
  {
    value: "Pacific/Pitcairn",
    label: "Pacific/Pitcairn"
  },
  {
    value: "America/Hermosillo",
    label: "America/Hermosillo"
  },
  {
    value: "America/Edmonton",
    label: "America/Edmonton"
  },
  {
    value: "America/Ojinaga",
    label: "America/Ojinaga"
  },
  {
    value: "America/Denver",
    label: "America/Denver"
  },
  {
    value: "America/Phoenix",
    label: "America/Phoenix"
  },
  {
    value: "America/Whitehorse",
    label: "America/Whitehorse"
  },
  {
    value: "America/Belize",
    label: "America/Belize"
  },
  {
    value: "America/Chicago",
    label: "America/Chicago"
  },
  {
    value: "America/Guatemala",
    label: "America/Guatemala"
  },
  {
    value: "America/Managua",
    label: "America/Managua"
  },
  {
    value: "America/Mexico_City",
    label: "America/Mexico_City"
  },
  {
    value: "America/Costa_Rica",
    label: "America/Costa_Rica"
  },
  {
    value: "America/El_Salvador",
    label: "America/El_Salvador"
  },
  {
    value: "America/Regina",
    label: "America/Regina"
  },
  {
    value: "America/Tegucigalpa",
    label: "America/Tegucigalpa"
  },
  {
    value: "America/Winnipeg",
    label: "America/Winnipeg"
  },
  {
    value: "Pacific/Easter",
    label: "Pacific/Easter"
  },
  {
    value: "Pacific/Galapagos",
    label: "Pacific/Galapagos"
  },
  {
    value: "America/Rio_Branco",
    label: "America/Rio_Branco"
  },
  {
    value: "America/Bogota",
    label: "America/Bogota"
  },
  {
    value: "America/Havana",
    label: "America/Havana"
  },
  {
    value: "America/Atikokan",
    label: "America/Atikokan"
  },
  {
    value: "America/Cancun",
    label: "America/Cancun"
  },
  {
    value: "America/Grand_Turk",
    label: "America/Grand_Turk"
  },
  {
    value: "America/Cayman",
    label: "America/Cayman"
  },
  {
    value: "America/Jamaica",
    label: "America/Jamaica"
  },
  {
    value: "America/Nassau",
    label: "America/Nassau"
  },
  {
    value: "America/New_York",
    label: "America/New_York"
  },
  {
    value: "America/Panama",
    label: "America/Panama"
  },
  {
    value: "America/Port-au-Prince",
    label: "America/Port-au-Prince"
  },
  {
    value: "America/Toronto",
    label: "America/Toronto"
  },
  {
    value: "America/Guayaquil",
    label: "America/Guayaquil"
  },
  {
    value: "America/Lima",
    label: "America/Lima"
  },
  {
    value: "America/Manaus",
    label: "America/Manaus"
  },
  {
    value: "America/St_Kitts",
    label: "America/St_Kitts"
  },
  {
    value: "America/Montserrat",
    label: "America/Montserrat"
  },
  {
    value: "America/Barbados",
    label: "America/Barbados"
  },
  {
    value: "America/St_Lucia",
    label: "America/St_Lucia"
  },
  {
    value: "America/Port_of_Spain",
    label: "America/Port_of_Spain"
  },
  {
    value: "America/Martinique",
    label: "America/Martinique"
  },
  {
    value: "America/St_Barthelemy",
    label: "America/St_Barthelemy"
  },
  {
    value: "America/Halifax",
    label: "America/Halifax"
  },
  {
    value: "Atlantic/Bermuda",
    label: "Atlantic/Bermuda"
  },
  {
    value: "America/St_Vincent",
    label: "America/St_Vincent"
  },
  {
    value: "America/Kralendijk",
    label: "America/Kralendijk"
  },
  {
    value: "America/Guadeloupe",
    label: "America/Guadeloupe"
  },
  {
    value: "America/Blanc-Sablon",
    label: "America/Blanc-Sablon"
  },
  {
    value: "America/Marigot",
    label: "America/Marigot"
  },
  {
    value: "America/Aruba",
    label: "America/Aruba"
  },
  {
    value: "America/Lower_Princes",
    label: "America/Lower_Princes"
  },
  {
    value: "America/Tortola",
    label: "America/Tortola"
  },
  {
    value: "America/Dominica",
    label: "America/Dominica"
  },
  {
    value: "America/St_Thomas",
    label: "America/St_Thomas"
  },
  {
    value: "America/Grenada",
    label: "America/Grenada"
  },
  {
    value: "America/Antigua",
    label: "America/Antigua"
  },
  {
    value: "America/Puerto_Rico",
    label: "America/Puerto_Rico"
  },
  {
    value: "America/Santo_Domingo",
    label: "America/Santo_Domingo"
  },
  {
    value: "America/Anguilla",
    label: "America/Anguilla"
  },
  {
    value: "America/Thule",
    label: "America/Thule"
  },
  {
    value: "America/Curacao",
    label: "America/Curacao"
  },
  {
    value: "America/La_Paz",
    label: "America/La_Paz"
  },
  {
    value: "America/Santiago",
    label: "America/Santiago"
  },
  {
    value: "America/Guyana",
    label: "America/Guyana"
  },
  {
    value: "America/Asuncion",
    label: "America/Asuncion"
  },
  {
    value: "America/Caracas",
    label: "America/Caracas"
  },
  {
    value: "America/St_Johns",
    label: "America/St_Johns"
  },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "America/Argentina/Buenos_Aires"
  },
  {
    value: "America/Sao_Paulo",
    label: "America/Sao_Paulo"
  },
  {
    value: "Antarctica/Palmer",
    label: "Antarctica/Palmer"
  },
  {
    value: "America/Punta_Arenas",
    label: "America/Punta_Arenas"
  },
  {
    value: "Atlantic/Stanley",
    label: "Atlantic/Stanley"
  },
  {
    value: "America/Cayenne",
    label: "America/Cayenne"
  },
  {
    value: "America/Miquelon",
    label: "America/Miquelon"
  },
  {
    value: "America/Paramaribo",
    label: "America/Paramaribo"
  },
  {
    value: "America/Montevideo",
    label: "America/Montevideo"
  },
  {
    value: "America/Godthab",
    label: "America/Godthab"
  },
  {
    value: "America/Noronha",
    label: "America/Noronha"
  },
  {
    value: "Atlantic/South_Georgia",
    label: "Atlantic/South_Georgia"
  },
  {
    value: "Atlantic/Azores",
    label: "Atlantic/Azores"
  },
  {
    value: "Atlantic/Cape_Verde",
    label: "Atlantic/Cape_Verde"
  },
  {
    value: "America/Scoresbysund",
    label: "America/Scoresbysund"
  },
  {
    value: "Africa/Abidjan",
    label: "Africa/Abidjan"
  },
  {
    value: "Africa/Accra",
    label: "Africa/Accra"
  },
  {
    value: "Africa/Bamako",
    label: "Africa/Bamako"
  },
  {
    value: "Africa/Bissau",
    label: "Africa/Bissau"
  },
  {
    value: "Africa/Conakry",
    label: "Africa/Conakry"
  },
  {
    value: "Africa/Dakar",
    label: "Africa/Dakar"
  },
  {
    value: "America/Danmarkshavn",
    label: "America/Danmarkshavn"
  },
  {
    value: "Europe/Isle_of_Man",
    label: "Europe/Isle_of_Man"
  },
  {
    value: "Europe/Dublin",
    label: "Europe/Dublin"
  },
  {
    value: "Africa/Freetown",
    label: "Africa/Freetown"
  },
  {
    value: "Atlantic/St_Helena",
    label: "Atlantic/St_Helena"
  },
  {
    value: "Africa/Lome",
    label: "Africa/Lome"
  },
  {
    value: "Europe/London",
    label: "Europe/London"
  },
  {
    value: "Africa/Monrovia",
    label: "Africa/Monrovia"
  },
  {
    value: "Africa/Nouakchott",
    label: "Africa/Nouakchott"
  },
  {
    value: "Africa/Ouagadougou",
    label: "Africa/Ouagadougou"
  },
  {
    value: "Atlantic/Reykjavik",
    label: "Atlantic/Reykjavik"
  },
  {
    value: "Europe/Jersey",
    label: "Europe/Jersey"
  },
  {
    value: "Europe/Guernsey",
    label: "Europe/Guernsey"
  },
  {
    value: "Africa/Banjul",
    label: "Africa/Banjul"
  },
  {
    value: "Africa/Sao_Tome",
    label: "Africa/Sao_Tome"
  },
  {
    value: "Antarctica/Troll",
    label: "Antarctica/Troll"
  },
  {
    value: "Africa/Casablanca",
    label: "Africa/Casablanca"
  },
  {
    value: "Africa/El_Aaiun",
    label: "Africa/El_Aaiun"
  },
  {
    value: "Atlantic/Canary",
    label: "Atlantic/Canary"
  },
  {
    value: "Europe/Lisbon",
    label: "Europe/Lisbon"
  },
  {
    value: "Atlantic/Faroe",
    label: "Atlantic/Faroe"
  },
  {
    value: "Africa/Windhoek",
    label: "Africa/Windhoek"
  },
  {
    value: "Africa/Algiers",
    label: "Africa/Algiers"
  },
  {
    value: "Europe/Amsterdam",
    label: "Europe/Amsterdam"
  },
  {
    value: "Europe/Andorra",
    label: "Europe/Andorra"
  },
  {
    value: "Europe/Belgrade",
    label: "Europe/Belgrade"
  },
  {
    value: "Europe/Berlin",
    label: "Europe/Berlin"
  },
  {
    value: "Europe/Malta",
    label: "Europe/Malta"
  },
  {
    value: "Europe/Bratislava",
    label: "Europe/Bratislava"
  },
  {
    value: "Europe/Brussels",
    label: "Europe/Brussels"
  },
  {
    value: "Europe/Budapest",
    label: "Europe/Budapest"
  },
  {
    value: "Europe/Copenhagen",
    label: "Europe/Copenhagen"
  },
  {
    value: "Europe/Gibraltar",
    label: "Europe/Gibraltar"
  },
  {
    value: "Europe/Ljubljana",
    label: "Europe/Ljubljana"
  },
  {
    value: "Arctic/Longyearbyen",
    label: "Arctic/Longyearbyen"
  },
  {
    value: "Europe/Luxembourg",
    label: "Europe/Luxembourg"
  },
  {
    value: "Europe/Madrid",
    label: "Europe/Madrid"
  },
  {
    value: "Europe/Monaco",
    label: "Europe/Monaco"
  },
  {
    value: "Europe/Oslo",
    label: "Europe/Oslo"
  },
  {
    value: "Europe/Paris",
    label: "Europe/Paris"
  },
  {
    value: "Europe/Podgorica",
    label: "Europe/Podgorica"
  },
  {
    value: "Europe/Prague",
    label: "Europe/Prague"
  },
  {
    value: "Europe/Rome",
    label: "Europe/Rome"
  },
  {
    value: "Europe/San_Marino",
    label: "Europe/San_Marino"
  },
  {
    value: "Europe/Sarajevo",
    label: "Europe/Sarajevo"
  },
  {
    value: "Europe/Skopje",
    label: "Europe/Skopje"
  },
  {
    value: "Europe/Stockholm",
    label: "Europe/Stockholm"
  },
  {
    value: "Europe/Tirane",
    label: "Europe/Tirane"
  },
  {
    value: "Africa/Tunis",
    label: "Africa/Tunis"
  },
  {
    value: "Europe/Vaduz",
    label: "Europe/Vaduz"
  },
  {
    value: "Europe/Vatican",
    label: "Europe/Vatican"
  },
  {
    value: "Europe/Vienna",
    label: "Europe/Vienna"
  },
  {
    value: "Europe/Warsaw",
    label: "Europe/Warsaw"
  },
  {
    value: "Europe/Zagreb",
    label: "Europe/Zagreb"
  },
  {
    value: "Europe/Zurich",
    label: "Europe/Zurich"
  },
  {
    value: "Africa/Bangui",
    label: "Africa/Bangui"
  },
  {
    value: "Africa/Malabo",
    label: "Africa/Malabo"
  },
  {
    value: "Africa/Brazzaville",
    label: "Africa/Brazzaville"
  },
  {
    value: "Africa/Porto-Novo",
    label: "Africa/Porto-Novo"
  },
  {
    value: "Africa/Douala",
    label: "Africa/Douala"
  },
  {
    value: "Africa/Kinshasa",
    label: "Africa/Kinshasa"
  },
  {
    value: "Africa/Lagos",
    label: "Africa/Lagos"
  },
  {
    value: "Africa/Libreville",
    label: "Africa/Libreville"
  },
  {
    value: "Africa/Luanda",
    label: "Africa/Luanda"
  },
  {
    value: "Africa/Ndjamena",
    label: "Africa/Ndjamena"
  },
  {
    value: "Africa/Niamey",
    label: "Africa/Niamey"
  },
  {
    value: "Africa/Bujumbura",
    label: "Africa/Bujumbura"
  },
  {
    value: "Africa/Gaborone",
    label: "Africa/Gaborone"
  },
  {
    value: "Africa/Harare",
    label: "Africa/Harare"
  },
  {
    value: "Africa/Khartoum",
    label: "Africa/Khartoum"
  },
  {
    value: "Africa/Kigali",
    label: "Africa/Kigali"
  },
  {
    value: "Africa/Blantyre",
    label: "Africa/Blantyre"
  },
  {
    value: "Africa/Lubumbashi",
    label: "Africa/Lubumbashi"
  },
  {
    value: "Africa/Lusaka",
    label: "Africa/Lusaka"
  },
  {
    value: "Africa/Maputo",
    label: "Africa/Maputo"
  },
  {
    value: "Africa/Juba",
    label: "Africa/Juba"
  },
  {
    value: "Asia/Damascus",
    label: "Asia/Damascus"
  },
  {
    value: "Asia/Amman",
    label: "Asia/Amman"
  },
  {
    value: "Europe/Athens",
    label: "Europe/Athens"
  },
  {
    value: "Asia/Beirut",
    label: "Asia/Beirut"
  },
  {
    value: "Europe/Bucharest",
    label: "Europe/Bucharest"
  },
  {
    value: "Africa/Cairo",
    label: "Africa/Cairo"
  },
  {
    value: "Europe/Chisinau",
    label: "Europe/Chisinau"
  },
  {
    value: "Asia/Hebron",
    label: "Asia/Hebron"
  },
  {
    value: "Europe/Helsinki",
    label: "Europe/Helsinki"
  },
  {
    value: "Europe/Kaliningrad",
    label: "Europe/Kaliningrad"
  },
  {
    value: "Europe/Kiev",
    label: "Europe/Kiev"
  },
  {
    value: "Europe/Mariehamn",
    label: "Europe/Mariehamn"
  },
  {
    value: "Asia/Nicosia",
    label: "Asia/Nicosia"
  },
  {
    value: "Europe/Riga",
    label: "Europe/Riga"
  },
  {
    value: "Europe/Sofia",
    label: "Europe/Sofia"
  },
  {
    value: "Europe/Tallinn",
    label: "Europe/Tallinn"
  },
  {
    value: "Africa/Tripoli",
    label: "Africa/Tripoli"
  },
  {
    value: "Europe/Vilnius",
    label: "Europe/Vilnius"
  },
  {
    value: "Asia/Jerusalem",
    label: "Asia/Jerusalem"
  },
  {
    value: "Africa/Johannesburg",
    label: "Africa/Johannesburg"
  },
  {
    value: "Africa/Mbabane",
    label: "Africa/Mbabane"
  },
  {
    value: "Africa/Maseru",
    label: "Africa/Maseru"
  },
  {
    value: "Asia/Kuwait",
    label: "Asia/Kuwait"
  },
  {
    value: "Asia/Baghdad",
    label: "Asia/Baghdad"
  },
  {
    value: "Asia/Qatar",
    label: "Asia/Qatar"
  },
  {
    value: "Asia/Bahrain",
    label: "Asia/Bahrain"
  },
  {
    value: "Asia/Riyadh",
    label: "Asia/Riyadh"
  },
  {
    value: "Asia/Aden",
    label: "Asia/Aden"
  },
  {
    value: "Africa/Addis_Ababa",
    label: "Africa/Addis_Ababa"
  },
  {
    value: "Indian/Antananarivo",
    label: "Indian/Antananarivo"
  },
  {
    value: "Africa/Asmara",
    label: "Africa/Asmara"
  },
  {
    value: "Africa/Dar_es_Salaam",
    label: "Africa/Dar_es_Salaam"
  },
  {
    value: "Africa/Djibouti",
    label: "Africa/Djibouti"
  },
  {
    value: "Africa/Kampala",
    label: "Africa/Kampala"
  },
  {
    value: "Indian/Mayotte",
    label: "Indian/Mayotte"
  },
  {
    value: "Africa/Mogadishu",
    label: "Africa/Mogadishu"
  },
  {
    value: "Indian/Comoro",
    label: "Indian/Comoro"
  },
  {
    value: "Africa/Nairobi",
    label: "Africa/Nairobi"
  },
  {
    value: "Europe/Minsk",
    label: "Europe/Minsk"
  },
  {
    value: "Europe/Moscow",
    label: "Europe/Moscow"
  },
  {
    value: "Antarctica/Syowa",
    label: "Antarctica/Syowa"
  },
  {
    value: "Europe/Istanbul",
    label: "Europe/Istanbul"
  },
  {
    value: "Asia/Tehran",
    label: "Asia/Tehran"
  },
  {
    value: "Asia/Yerevan",
    label: "Asia/Yerevan"
  },
  {
    value: "Asia/Baku",
    label: "Asia/Baku"
  },
  {
    value: "Asia/Tbilisi",
    label: "Asia/Tbilisi"
  },
  {
    value: "Asia/Dubai",
    label: "Asia/Dubai"
  },
  {
    value: "Asia/Muscat",
    label: "Asia/Muscat"
  },
  {
    value: "Indian/Mauritius",
    label: "Indian/Mauritius"
  },
  {
    value: "Indian/Reunion",
    label: "Indian/Reunion"
  },
  {
    value: "Europe/Samara",
    label: "Europe/Samara"
  },
  {
    value: "Indian/Mahe",
    label: "Indian/Mahe"
  },
  {
    value: "Asia/Kabul",
    label: "Asia/Kabul"
  },
  {
    value: "Indian/Kerguelen",
    label: "Indian/Kerguelen"
  },
  {
    value: "Indian/Maldives",
    label: "Indian/Maldives"
  },
  {
    value: "Antarctica/Mawson",
    label: "Antarctica/Mawson"
  },
  {
    value: "Asia/Karachi",
    label: "Asia/Karachi"
  },
  {
    value: "Asia/Dushanbe",
    label: "Asia/Dushanbe"
  },
  {
    value: "Asia/Ashgabat",
    label: "Asia/Ashgabat"
  },
  {
    value: "Asia/Tashkent",
    label: "Asia/Tashkent"
  },
  {
    value: "Asia/Qyzylorda",
    label: "Asia/Qyzylorda"
  },
  {
    value: "Asia/Yekaterinburg",
    label: "Asia/Yekaterinburg"
  },
  {
    value: "Asia/Colombo",
    label: "Asia/Colombo"
  },
  {
    value: "Asia/Kolkata",
    label: "Asia/Kolkata"
  },
  {
    value: "Asia/Kathmandu",
    label: "Asia/Kathmandu"
  },
  {
    value: "Asia/Dhaka",
    label: "Asia/Dhaka"
  },
  {
    value: "Asia/Thimphu",
    label: "Asia/Thimphu"
  },
  {
    value: "Asia/Urumqi",
    label: "Asia/Urumqi"
  },
  {
    value: "Asia/Almaty",
    label: "Asia/Almaty"
  },
  {
    value: "Indian/Chagos",
    label: "Indian/Chagos"
  },
  {
    value: "Asia/Bishkek",
    label: "Asia/Bishkek"
  },
  {
    value: "Asia/Omsk",
    label: "Asia/Omsk"
  },
  {
    value: "Antarctica/Vostok",
    label: "Antarctica/Vostok"
  },
  {
    value: "Indian/Cocos",
    label: "Indian/Cocos"
  },
  {
    value: "Asia/Yangon",
    label: "Asia/Yangon"
  },
  {
    value: "Indian/Christmas",
    label: "Indian/Christmas"
  },
  {
    value: "Antarctica/Davis",
    label: "Antarctica/Davis"
  },
  {
    value: "Asia/Hovd",
    label: "Asia/Hovd"
  },
  {
    value: "Asia/Bangkok",
    label: "Asia/Bangkok"
  },
  {
    value: "Asia/Ho_Chi_Minh",
    label: "Asia/Ho_Chi_Minh"
  },
  {
    value: "Asia/Phnom_Penh",
    label: "Asia/Phnom_Penh"
  },
  {
    value: "Asia/Vientiane",
    label: "Asia/Vientiane"
  },
  {
    value: "Asia/Novosibirsk",
    label: "Asia/Novosibirsk"
  },
  {
    value: "Asia/Jakarta",
    label: "Asia/Jakarta"
  },
  {
    value: "Australia/Perth",
    label: "Australia/Perth"
  },
  {
    value: "Asia/Brunei",
    label: "Asia/Brunei"
  },
  {
    value: "Asia/Makassar",
    label: "Asia/Makassar"
  },
  {
    value: "Asia/Macau",
    label: "Asia/Macau"
  },
  {
    value: "Asia/Shanghai",
    label: "Asia/Shanghai"
  },
  {
    value: "Asia/Hong_Kong",
    label: "Asia/Hong_Kong"
  },
  {
    value: "Asia/Irkutsk",
    label: "Asia/Irkutsk"
  },
  {
    value: "Asia/Kuala_Lumpur",
    label: "Asia/Kuala_Lumpur"
  },
  {
    value: "Asia/Manila",
    label: "Asia/Manila"
  },
  {
    value: "Asia/Singapore",
    label: "Asia/Singapore"
  },
  {
    value: "Asia/Taipei",
    label: "Asia/Taipei"
  },
  {
    value: "Asia/Ulaanbaatar",
    label: "Asia/Ulaanbaatar"
  },
  {
    value: "Australia/Eucla",
    label: "Australia/Eucla"
  },
  {
    value: "Asia/Dili",
    label: "Asia/Dili"
  },
  {
    value: "Asia/Jayapura",
    label: "Asia/Jayapura"
  },
  {
    value: "Asia/Tokyo",
    label: "Asia/Tokyo"
  },
  {
    value: "Asia/Pyongyang",
    label: "Asia/Pyongyang"
  },
  {
    value: "Asia/Seoul",
    label: "Asia/Seoul"
  },
  {
    value: "Pacific/Palau",
    label: "Pacific/Palau"
  },
  {
    value: "Asia/Chita",
    label: "Asia/Chita"
  },
  {
    value: "Australia/Adelaide",
    label: "Australia/Adelaide"
  },
  {
    value: "Australia/Darwin",
    label: "Australia/Darwin"
  },
  {
    value: "Australia/Brisbane",
    label: "Australia/Brisbane"
  },
  {
    value: "Australia/Sydney",
    label: "Australia/Sydney"
  },
  {
    value: "Pacific/Guam",
    label: "Pacific/Guam"
  },
  {
    value: "Pacific/Saipan",
    label: "Pacific/Saipan"
  },
  {
    value: "Pacific/Chuuk",
    label: "Pacific/Chuuk"
  },
  {
    value: "Antarctica/DumontDUrville",
    label: "Antarctica/DumontDUrville"
  },
  {
    value: "Pacific/Port_Moresby",
    label: "Pacific/Port_Moresby"
  },
  {
    value: "Asia/Vladivostok",
    label: "Asia/Vladivostok"
  },
  {
    value: "Australia/Lord_Howe",
    label: "Australia/Lord_Howe"
  },
  {
    value: "Pacific/Bougainville",
    label: "Pacific/Bougainville"
  },
  {
    value: "Antarctica/Casey",
    label: "Antarctica/Casey"
  },
  {
    value: "Pacific/Kosrae",
    label: "Pacific/Kosrae"
  },
  {
    value: "Pacific/Noumea",
    label: "Pacific/Noumea"
  },
  {
    value: "Pacific/Norfolk",
    label: "Pacific/Norfolk"
  },
  {
    value: "Asia/Sakhalin",
    label: "Asia/Sakhalin"
  },
  {
    value: "Pacific/Guadalcanal",
    label: "Pacific/Guadalcanal"
  },
  {
    value: "Pacific/Efate",
    label: "Pacific/Efate"
  },
  {
    value: "Pacific/Fiji",
    label: "Pacific/Fiji"
  },
  {
    value: "Pacific/Tarawa",
    label: "Pacific/Tarawa"
  },
  {
    value: "Pacific/Majuro",
    label: "Pacific/Majuro"
  },
  {
    value: "Pacific/Nauru",
    label: "Pacific/Nauru"
  },
  {
    value: "Pacific/Auckland",
    label: "Pacific/Auckland"
  },
  {
    value: "Antarctica/McMurdo",
    label: "Antarctica/McMurdo"
  },
  {
    value: "Asia/Kamchatka",
    label: "Asia/Kamchatka"
  },
  {
    value: "Pacific/Funafuti",
    label: "Pacific/Funafuti"
  },
  {
    value: "Pacific/Wake",
    label: "Pacific/Wake"
  },
  {
    value: "Pacific/Wallis",
    label: "Pacific/Wallis"
  },
  {
    value: "Pacific/Chatham",
    label: "Pacific/Chatham"
  },
  {
    value: "Pacific/Apia",
    label: "Pacific/Apia"
  },
  {
    value: "Pacific/Enderbury",
    label: "Pacific/Enderbury"
  },
  {
    value: "Pacific/Fakaofo",
    label: "Pacific/Fakaofo"
  },
  {
    value: "Pacific/Tongatapu",
    label: "Pacific/Tongatapu"
  },
  {
    value: "Pacific/Kiritimati",
    label: "Pacific/Kiritimati"
  }
]